import * as d3 from 'd3';
// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/grouped-bar-chart

export const d3GroupedBarChartMixins = {
  methods: {
    GroupedBarChart(
      data,
      domElementId,
      {
        x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        y = (d) => d, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        title, // given d in data, returns the title text
        marginTop = 30, // top margin, in pixels
        marginRight = 0, // right margin, in pixels
        marginBottom = 100, // bottom margin, in pixels
        marginLeft = 40, // left margin, in pixels
        width = 1200, // outer width, in pixels
        height = 400, // outer height, in pixels
        xDomain, // array of x-values
        xRange = [marginLeft, width - marginRight], // [xmin, xmax]
        xPadding = 0.2, // amount of x-range to reserve to separate groups
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [ymin, ymax]
        zDomain, // array of z-values
        zPadding = 0.01, // amount of x-range to reserve to separate bars
        yFormat, // a format specifier string for the y-axis
        yLabel, // a label for the y-axis
        colors = d3.schemeTableau10, // array of colors
      } = {},
    ) {
      // Compute values.
      const X = d3.map(data, x);
      const Y = d3.map(data, y);
      const Z = d3.map(data, z);

      // Compute default domains, and unique the x- and z-domains.
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, d3.max(Y)];
      if (zDomain === undefined) zDomain = Z;
      xDomain = new d3.InternSet(xDomain);
      zDomain = new d3.InternSet(zDomain);

      // Omit any data not present in both the x- and z-domain.
      const I = d3
        .range(X.length)
        .filter((i) => xDomain.has(X[i]) && zDomain.has(Z[i]));

      // Construct scales, axes, and formats.
      const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding);
      const xzScale = d3
        .scaleBand(zDomain, [0, xScale.bandwidth()])
        .padding(zPadding);
      const yScale = yType(yDomain, yRange);
      const zScale = d3.scaleOrdinal(zDomain, colors);
      const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
      const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);

      // Compute titles.
      if (title === undefined) {
        const formatValue = yScale.tickFormat(100, yFormat);
        title = (i) => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}`;
      } else {
        const O = d3.map(data, (d) => d);
        const T = title;
        title = (i) => T(O[i], i, data);
      }

      // Create the parent container
      const parent = d3
        .select(domElementId)
        .html('')
        .append('div')
        .style('overflow-x', 'scroll')
        .style('overflow-y', 'hidden')
        .style('-webkit-overflow-scrolling', 'touch')
        .style('position', 'relative')
        .style('height', height + 'px');

      // Create the yAxis container
      const yAxisContainer = parent
        .append('div')
        .style('position', 'sticky')
        .style('left', '0')
        .style('top', '0')
        .style('overflow', 'hidden')
        .style('width', marginLeft + 40 + 'px')
        .style('height', height + 'px').style('z-index', 49);

      // Create the chart SVG.
      const svg = parent
        .append('div')
        .style('position', 'absolute')
        .style('left', marginLeft + 'px')
        .style('top', '0')
        .style('overflow', 'hidden')
        .style('width', width - marginLeft + 'px')
        .style('height', height + 'px')
        .style('z-index', '1')
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

      const yAxisSvg = yAxisContainer
        .append('svg')
        .attr('width', marginLeft + 120)
        .attr('height', height);

      yAxisSvg
        .append('g')
        .attr('transform', `translate(${marginLeft}, -12.5)`)
        .call(yAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick line')
            .clone()
            .attr('x2', width - marginLeft - marginRight)
            .attr('stroke-opacity', 0.1),
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', -marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel),
        );

      const bar = svg
        .append('g')
        .selectAll('rect')
        .data(I)
        .join('rect')
        .attr('x', (i) => xScale(X[i]) + xzScale(Z[i]))
        .attr('y', (i) => yScale(Y[i]))
        .attr('width', xzScale.bandwidth())
        .attr('height', (i) => yScale(0) - yScale(Y[i]))
        .attr('fill', (i) => zScale(Z[i]));

      if (title) bar.append('title').text(title);

      svg
        .append('g')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .call(xAxis)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('transform', 'rotate(-90) translate(-10, -12.5)');

      // return Object.assign(svg.node(), { scales: { color: zScale } });
      return svg.node();
    },
  },
};
