<template>
  <KTCodePreview title="Doanh thu" class="overflow-hidden" style="height: auto">
    <template v-slot:preview>
      <b-container fluid ref="barChartContainer">
        <b-row class="align-items-end">
          <b-col md="5" sm="12">
            <b-form-group label="Nhập cửa hàng" label-class="font-weight-bold">
              <MultiselectOptionV2
                id="store-options"
                :model.sync="selectedStores"
                :options="filteredStoreList"
                :suggestion-name="'name'"
                :trackBy="'id'"
                :placeholder="'cửa hàng'"
                @select="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreList,
                    trackBy: 'id',
                    filteredOptions: filteredStoreList,
                    checked: true,
                  })
                "
                @remove="
                  selectResourceHandler({
                    option: $event,
                    options: filteredStoreList,
                    trackBy: 'id',
                    filteredOptions: filteredStoreList,
                    checked: false,
                  })
                "
                :select-all-option="true"
                @searchChange="onInputChangeStore($event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" sm="12">
            <b-form-group
              label="Nhập ngày bắt đầu/Theo ngày"
              label-class="font-weight-bold"
            >
              <date-picker
                size="sm"
                class="form-control form-control-sm"
                :config="dpConfig"
                v-model="startDate"
                id="report-start-date"
              ></date-picker>
            </b-form-group>
          </b-col>

          <b-col md="3" sm="12">
            <b-form-group
              label="Nhập ngày kết thúc"
              label-class="font-weight-bold"
            >
              <date-picker
                size="sm"
                class="form-control form-control-sm"
                :config="dpConfig"
                v-model="endDate"
                id="report-end-date"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="1" sm="12">
            <b-form-group>
              <b-button
                size="sm"
                variant="primary"
                @click="fetchData"
                :disabled="
                  !!(
                    isLoading.dataCategoriesMonthly &&
                    isLoading.dataCategoriesStores &&
                    isLoading.dataDailyBarChart &&
                    isLoading.dataGroupedDaily
                  )
                "
                >Lọc
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-8">
          <b-col md="5" sm="12">
            <AboriginalTreeselect
              name="danh mục"
              :model="selectedCategories"
              :options="options"
              :loadOptions="loadOptions"
              :multiple="true"
              @update:model="(value) => (selectedCategories = value)"
            />
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="enableChartAnimation"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                Bật hiệu ứng cho chart
                <em>(Chỉ có hiệu lực khi khởi tạo dữ liệu mới)</em>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="1"></b-col>
        </b-row>
      </b-container>
      <b-row class="shadow-sm p-3 mb-4 bg-white rounded">
        <b-col md="6" sm="12">
          <D3ArrayChartContainer
            id="bi-report-revenue-daily-bar-chart"
            :is-loading="isLoading.dataDailyBarChart"
            :chart-height="chartHeight"
            class="mb-4"
            title="Doanh thu theo ngày"
          />
        </b-col>
        <b-col md="6" sm="12">
          <div id="bi-report-revenue-daily-grouped-bar-chart-legend"></div>
          <D3ArrayChartContainer
            :has-legends="true"
            id="bi-report-revenue-daily-grouped-bar-chart"
            :is-loading="isLoading.dataGroupedDaily"
            :chart-height="chartHeight"
            title="Số lượng nhập và bán theo ngày"
            class="mb-4"
          />
        </b-col>
      </b-row>
      <b-row class="shadow-sm p-3 mb-4 bg-white rounded">
        <b-col>
          <D3ObjectChartContainer
            class="pt-8 mb-4 px-4"
            id="bi-report-revenue-categories"
            :data="dataCategoriesStores"
            :is-loading="isLoading.dataCategoriesStores"
            :chart-height="chartHeight"
            :title="`Categories - Cửa hàng (${startDate} - ${endDate})`"
          />
        </b-col>
      </b-row>

      <b-row class="shadow-sm p-3 mb-4 bg-white rounded">
        <b-col>
          <D3ObjectChartContainer
            class="pt-8 mb-4 px-4"
            id="bi-report-categories-monthly"
            :data="dataCategoriesMonthly"
            :is-loading="isLoading.dataCategoriesMonthly"
            :chart-height="chartHeight"
            :title="
              `Categories - Tháng ${payloadMonth.month} (${payloadMonth.startDate} - ${payloadMonth.endDate})`
            "
          />
        </b-col>
      </b-row>
    </template>
  </KTCodePreview>
</template>
<script>
import {
  DP_CONFIG,
  getAddTimeByUnit,
  getCurrentDateByFormat,
  getEndOfMonth,
  getMonth,
  getStartOfMonth,
} from '@/utils/date';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { formatPrice, makeToastFaile, removeAccents } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { DEBOUNCE, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { swalMixin } from '@/view/mixins';
import { assign, cloneDeep, find, isEmpty, map } from 'lodash';
import debounce from 'debounce';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import D3ObjectChartContainer from '@/view/components/bi/D3ObjectChartContainer.vue';
import { d3HierarchyMixins } from '@/view/mixins/d3/d3HierarchyMixins';
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';
import AboriginalTreeselect from '@/view/base/treeselect/AboriginalTreeselect.vue';
import D3ArrayChartContainer from '@/view/components/bi/D3ArrayChartContainer.vue';
import { d3BarChartMixins } from '@/view/mixins/d3/d3BarChartMixins';
import { d3GroupedBarChartMixins } from '@/view/mixins/d3/d3GroupedBarChartMixins';
import EachAfterTranslation from '@/utils/models/D3/EachAfter';
import * as d3 from 'd3';
import Legend from '@/view/components/bi/Legend';
import MultiselectOptionV2 from '@/view/base/multiselect/MultiselectOptionV2.vue';
import vueMultiselectMixin from '@/view/mixins/vue-multiselect.mixins';
import moment from 'moment';

export default {
  components: {
    MultiselectOptionV2,
    D3ArrayChartContainer,
    AboriginalTreeselect,
    KTCodePreview,
    D3ObjectChartContainer,
  },
  mixins: [
    swalMixin,
    d3HierarchyMixins,
    d3BarChartMixins,
    d3GroupedBarChartMixins,
    vueMultiselectMixin,
  ],
  data() {
    return {
      dpConfig: DP_CONFIG.date,
      storeName: '',
      storeAddress: '',
      totalProductSold: 0,
      revenuePerStore: 0,
      chartHeight: 680,

      filteredStoreList: [],

      // main data
      dataCategoriesMonthly: {},
      dataCategoriesStores: {},
      dataDailyBarChart: [],
      dataGroupedDaily: [],

      // loading state for each main data
      isLoading: {
        dataCategoriesMonthly: false,
        dataCategoriesStores: false,
        dataDailyBarChart: false,
        dataGroupedDaily: false,
      },

      storeKeyword: '',
      storeIds: [],
      startDate: '',
      endDate: '',

      selectedCategories: [],
      options: [],
      enableChartAnimation: false,

      selectedStores: [],
    };
  },
  methods: {
    fetchStoresByJobTitle() {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/bi-report/stores/job-title?storeKeyword=${this.storeKeyword}`,
        )
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              return data;
            }
          })
          .then((data) => {
            this.$set(this, 'storeIds', data.data);

            this.filteredStoreList = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            this.selectedStores = data.data.map((store) => {
              this.$set(store, 'checked', true);
              return store;
            });
            resolve(); // Resolve the promise once data is fetched and updated
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text:
                error.response.data.message ??
                'Không lấy được danh sách các cửa hàng',
            });
            reject(error); // Reject the promise if there's an error
          });
      });
    },
    debounceStoreInput: debounce(function() {
      this.fetchStoresByJobTitle();
    }, DEBOUNCE.SEARCH_DELAY),
    fetchData() {
      if (!this.startDate || !this.startDate.length) {
        this.popupSwalWarning({
          title: 'Cảnh báo!',
          text: 'Bạn chưa chọn ngày bắt đầu',
        });
        return;
      }

      for (const key in this.isLoading) {
        this.isLoading[key] = true;
      }

      const stores = this.selectedStores
        ? this.selectedStores
            .map((store) => {
              if (store.checked) return Number(store.id);
            })
            .sort()
        : this.storeIds;
      const payload = {
        storeIds: stores,
        startDate: this.startDate,
        endDate: this.endDate,
        categoryIds: [...this.selectedCategories]
          .map((selectedCategory) => Number(selectedCategory))
          .sort(),
      };
      ApiService.post(cmdUrl.BIReport.root, payload)
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho Report Categories - Cửa hàng',
            });
            return;
          }
          this.$set(this, 'dataCategoriesStores', {
            name: 'chart1',
            children: data.data,
          });
          return data.data;
        })
        .then(() => {
          this.$nextTick(() => {
            this.initializeD3HierarchyChart({
              domElementId: '#bi-report-revenue-categories',
              data: Object.assign({}, this.dataCategoriesStores),
              receivedBarStep: 27,
              sumBy: 'value',
              eachAfters: [
                new EachAfterTranslation({
                  name: 'totalQuantity',
                  value: 'SL bán',
                }),
              ],

              endAlignmentFontSize: '0.8rem',
              enableChartAnimation: this.enableChartAnimation,
              containerRef: this.$refs.barChartContainer,
              additionalBarTextDisplayType: 'inline',
            });
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.dataCategoriesStores = false;
        });

      ApiService.post(cmdUrl.BIReport.categoriesMonthly, payload)
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho report Doanh thu: Categories - Tháng',
            });
            return;
          }

          this.$set(this, 'dataCategoriesMonthly', {
            name: 'chart2',
            children: data.data,
          });
        })
        .then(() => {
          this.$nextTick(() => {
            const root = this.initializeD3HierarchyChart({
              domElementId: '#bi-report-categories-monthly',
              data: Object.assign({}, this.dataCategoriesMonthly),
              receivedBarStep: 27,
              sumBy: 'netSales',
              eachAfters: [
                new EachAfterTranslation({
                  name: 'totalQuantity',
                  value: 'SL bán',
                }),
                new EachAfterTranslation({
                  name: 'totalQuantityInStock',
                  value: 'Kho',
                }),
              ],
              endAlignmentFontSize: '0.8rem',
              additionalBarTextDisplayType: 'inline',
              enableChartAnimation: this.enableChartAnimation,
              containerRef: this.$refs.barChartContainer,
            });
            if (!isEmpty(root)) {
              this.revenuePerStore = root.value;
              this.totalProductSold = root.totalQuantity;
            }
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.dataCategoriesMonthly = false;
        });
      ApiService.post(cmdUrl.BIReportResource.daily.revenue, payload)
        .then(({ data }) => {
          if (!data.data || data.data.length < 1) {
            this.popupSwalWarning({
              title: 'Cảnh báo!',
              text: 'Không có dữ liệu cho Report Doanh thu: theo Ngày',
            });
            return;
          }
          return data.data;
        })
        .then((data) => {
          this.dataDailyBarChart = data.sort((dataA, dataB) =>
            moment(dataA.createdAtDate, 'DD/MM/YYYY').isBefore(
              moment(dataB.createdAtDate, 'DD/MM/YYYY'),
            )
              ? -1
              : 1,
          );
          const numKinds = ['SL Nhập (SI)', 'SL Bán (SO)'];
          const formattedData = this.dataDailyBarChart.map((item) => {
            return {
              name: item.createdAtDate,
              'SL Nhập (SI)': item.stockImport,
              'SL Bán (SO)': item.totalQuantity,
            };
          });
          const saleOrdersAndImport = numKinds.flatMap((kind) =>
            formattedData.map((d) => ({
              date: d.name,
              kind,
              stockImport: d[kind],
            })),
          );
          this.dataGroupedDaily = saleOrdersAndImport;

          this.$nextTick(() => {
            this.initializeD3BarChart({
              data: this.dataDailyBarChart,
              domElementId: '#bi-report-revenue-daily-bar-chart',
              margin: {
                top: 30,
                right: 30,
                bottom: 80,
                left: 40,
              },
              axes: {
                xAxis: 'createdAtDate',
                yAxis: 'netSales',
              },
              textConstruction: {
                createdAtDate: 'Ngày',
                netSales: { label: 'Doanh thu', willConvertCurrency: true },
                totalQuantity: 'SL bán',
              },
              yAxisLabel: 'Doanh thu (VNĐ)',
            });
            this.GroupedBarChart(
              this.dataGroupedDaily,
              '#bi-report-revenue-daily-grouped-bar-chart',
              {
                x: (d) => d.date,
                y: (d) => d.stockImport,
                z: (d) => d.kind,
                xDomain: d3.map(saleOrdersAndImport, (d) => d.date),
                yLabel: '↑ Số lượng (đơn vị)',
                zDomain: numKinds,
                colors: d3.schemeSpectral[numKinds.length + 2],
                height: 500,
              },
            );
            Legend(
              d3.scaleOrdinal(numKinds, d3.schemeSpectral[numKinds.length + 2]),
              {
                title: 'Số lượng nhập và bán',
                tickSize: 0,
                domElementId:
                  '#bi-report-revenue-daily-grouped-bar-chart-legend',
                marginLeft: 40,
              },
            );
          });
        })
        .catch((error) => {
          if (error) {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
          }
        })
        .finally(() => {
          this.isLoading.dataDailyBarChart = false;
          this.isLoading.dataGroupedDaily = false;
        });
    },
    onSelectedStore(value) {
      this.storeIds = value.item.id;
    },
    onInputChangeStore(textInput = '') {
      this.searchStore(textInput);
    },

    searchStore(textInput) {
      let options = cloneDeep(this.selectedStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStoreList = map(options, (obj) => {
          return assign(obj, find(this.filteredStoreList, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStoreList, { id: obj.id }));
      });

      this.filteredStoreList = this.filterOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },

    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchParentCategories() {
      return new Promise((resolve, reject) => {
        ApiService.get(cmdUrl.BIReportResource.categories.parents)
          .then(({ data }) => {
            this.options = data.data;
            resolve();
          })
          .catch((error) => {
            this.popupSwalError({
              title: 'Lỗi!',
              text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
            });
            reject(error);
          });
      });
    },
    fetchStoreInfo(id) {
      if (!id) return;
      ApiService.get(`stores/${id}`)
        .then(({ data }) => {
          this.storeName = data.data.name;
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    loadOptions({ action, parentNode, callback }) {
      ApiService.get(
        cmdUrl.BIReportResource.categories.children.replace(
          '{parentId}',
          parentNode.id,
        ),
      )
        .then(({ data }) => {
          if (action === LOAD_CHILDREN_OPTIONS) {
            parentNode.children = data.data;
            callback();
          }
        })
        .catch((error) => {
          this.popupSwalError({
            title: 'Lỗi!',
            text: error.response.data.message ?? 'Kiểm tra lại kết nối mạng',
          });
        });
    },

    getPayloadOnMount() {
      const currentDate = getCurrentDateByFormat({
        enableCustomCurrentDate: true,
        currentDate: '01/03/2023',
      });
      const designatedEndDate = getAddTimeByUnit({
        amount: 1,
        unit: 'months',
        currentDate,
        enableCustomCurrentDate: true,
      });
      this.startDate = currentDate;
      this.endDate = designatedEndDate;
    },
    storeKeywordChangeHandler(textInput) {
      this.storeKeyword = textInput;
    },
  },

  watch: {
    multiple(newValue) {
      if (newValue) {
        this.value = this.value ? [this.value] : [];
      } else {
        this.value = this.value[0];
      }
    },
  },
  mounted: async function() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo ngành hàng', route: '/bi-report' },
      { title: 'Doanh thu' },
    ]);

    this.getPayloadOnMount();
    await this.fetchParentCategories();
    await this.fetchStoresByJobTitle();
    await this.fetchData();
  },
  computed: {
    formattedRevenue() {
      return formatPrice(this.revenuePerStore);
    },
    payloadMonth() {
      return {
        month: getMonth(this.startDate) + 1,
        startDate: getStartOfMonth(this.startDate),
        endDate: getEndOfMonth(this.startDate),
      };
    },
  },
};
</script>
