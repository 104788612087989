const { genMultilineRegexp } = require("./common");

exports.isValidPhoneNumber = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(value);
};

exports.isValidEmail = (string) => {
  if (typeof string === 'undefined' || string === null || string === '') {
    return true;
  }

  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegExp.test(string);
};

exports.isValidTaxNo = (taxNo) => {
  if (typeof taxNo === 'undefined' || taxNo === null || taxNo === '') {
    return true;
  }

  const hasTenNumber = /^[0-9]{10}$/.test(taxNo);
  const hasCombineWithDashThreeNumber = /(\d{10}-\d{3})$/.test(taxNo);

  const isValid = hasTenNumber || hasCombineWithDashThreeNumber;
  return isValid;
};

exports.isNullishObject = (obj) => {
  if (!obj) return true;

  return Object.values(obj).every((value) =>
    [null, undefined, ''].includes(value),
  );
};

exports.isObject = (target) => {
  return target
    ? Object.prototype.toString.call(target) === '[object Object]'
    : false;
};

exports.isEmptyObject = (target) => {
  if (!this.isObject(target)) return false;
  return target && Object.keys(target).length;
};
exports.hasSpecialCharacters = (string) => {
  if (typeof string === 'undefined' || string === null || string === '') {
    return null;
  }

  const regexSpecialCharacters = /[\s~`!@#$%^&*+=[\]\\';,/{}|\\":<>?()._]/g;
  const normalizedString = string.normalize().trim();
  return !regexSpecialCharacters.test(normalizedString);
};

exports.checkAscent = (string) => {
  if (!string || !string.trim()) return '';

  const regexAscent = genMultilineRegexp([
      'à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ',
      'è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ',
      'ì|í|ị|ỉ|ĩ',
      'ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ',
      'ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ',
      'ỳ|ý|ỵ|ỷ|ỹ',
      'đ',
    ], 'g');

  return regexAscent.test(string);
}

const isObject = (object) => {
  return object && object.constructor === Object
}
exports.isEmptyObject= (object) => {
  return isObject && !Object.keys(object).length
}
exports.isBoolean = (value) => {
  return typeof value === 'boolean';
}

exports.isString = (value) => {
  return typeof value === 'string' || value instanceof String;
}

exports.MAX_LENGTH_VAT_CODE = 12;
exports.MAX_LENGTH_VAT_SERIAL = 12;
