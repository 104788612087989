import { isString } from '@/utils/validation';

export default class EachAfterTranslation {
  /**
   * @class EachAfterTranslation
   * @description EachAfter logic model for constructing additional bar text,
   * especially for Hierarchy D3 Bar chart with desired translation
   *
   * This will be used in the initializeD3BarChart() method.
   * Consequentially, in the constructAdditionalD3BarText.
   *
   * Both of these methods are located in the d3HierarchyMixins.js
   *
   * @param {String} name
   * @param {String} value
   */
  constructor({ name = '', value }) {
    this.name = isString(name) && name.length > 0 ? name.trim() : '';
    this.value = isString(value) && value.length > 0 ? value.trim() : '';
  }
}
