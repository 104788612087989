import * as d3 from 'd3';
import { formatPrice } from '@/utils/common';
import { BAR_CHART_LABEL_TYPE } from '@/view/mixins/d3/constants';

const WORDS_TO_BREAK = 3;

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/bar-chart/2?intent=fork

// Forked from the above chart.
export const d3BarChartMixins = {
  methods: {
    initializeD3BarChart({
      data,
      yAxisLabel = 'Doanh thu (VNĐ)',
      width = 680,
      height = 500,
      domElementId,
      margin,
      axes,
      textConstruction,
      xAxisLabelType,
    }) {
      const marginTop = margin.top || 20;
      const marginRight = margin.right || 20;
      const marginBottom = margin.bottom || 30;
      const marginLeft = margin.left || 40;

      // Declare the x (horizontal position) scale.
      const x = d3
        .scaleBand()
        .domain(data.map((d) => d[axes.xAxis]))
        .range([marginLeft, width - marginRight])
        .padding(0.1);

      // Declare the y (vertical position) scale.
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d[axes.yAxis])])
        .range([height - marginBottom, marginTop]);

      // Create the parent container.
      const parent = d3
        .select(domElementId)
        .html('')
        .append('div')
        .style('overflow-x', 'scroll')
        .style('overflow-y', 'hidden')
        .style('-webkit-overflow-scrolling', 'touch')
        .style('position', 'relative')
        .style('height', height + 'px');

      // Create the yAxis container.
      const yAxisContainer = parent
        .append('div')
        .style('position', 'sticky')
        .style('left', '0')
        .style('top', '0')
        .style('overflow', 'hidden')
        .style('width', marginLeft + 40 + 'px')
        .style('height', height + 'px')
        .style('z-index', 49);

      // Create the chart SVG.
      const svg = parent
        .append('div')
        .style('position', 'absolute')
        .style('left', marginLeft + 'px')
        .style('top', '0')
        .style('overflow', 'hidden')
        .style('width', width - marginLeft + 'px')
        .style('height', height + 'px')
        .style('z-index', '1')
        .append('svg')
        .attr('id', `${domElementId}-svg`)
        .attr('width', width + 20)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'height: auto;');

      // Add a rect for each bar.
      svg
        .append('g')
        .attr('fill', 'steelblue')
        .selectAll()
        .data(data)
        .join('rect')
        .attr('x', (d) => x(d[axes.xAxis]))
        .attr('y', (d) => y(d[axes.yAxis]))
        .attr('height', (d) => y(0) - y(d[axes.yAxis]))
        .attr('width', x.bandwidth())
        .append('title')
        .text((d) => this.constructText(d, textConstruction));

      // Add the x-axis and label.
      svg
        .append('g')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0));

      switch (xAxisLabelType) {
        case BAR_CHART_LABEL_TYPE.X_AXIS.BREAK_WORD:
          svg.selectAll('g.tick text').each(function(d) {
            const splitXAxisLabelText = d.split(' ').slice(0, WORDS_TO_BREAK);
            const textElement = d3.select(this); // Select the current text element

            textElement.text('');

            // Append a tspan for each part of the split text
            splitXAxisLabelText.forEach((part, i) => {
                textElement
                  .append('tspan')
                  .attr('class', 'tspan-' + i)
                  .attr('x', 0)
                  .attr('dy', 16)
                  .text(part + (i >= WORDS_TO_BREAK - 1 ? '...' : ''));
            });
          });
          break;
        default:
          svg
            .selectAll('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -4)
            .attr('x', -36);
          break;
      }

      // Create the yAxis SVG.
      const yAxisSvg = yAxisContainer
        .append('svg')
        .attr('width', marginLeft + 40)
        .attr('height', height);

      yAxisSvg
        .append('g')
        .attr('transform', `translate(${marginLeft},0)`)
        .call(d3.axisLeft(y).ticks(height / 80, 's'))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .append('text')
            .attr('x', -marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yAxisLabel),
        );

      // Return the parent container.
      return parent.node();
    },
    constructText(d, textConstruction) {
      let str = '';
      for (const key in textConstruction) {
        if (textConstruction[key].constructor.name === 'String') {
          str += `${textConstruction[key]}: ${d[key]}\n`;
        }

        if (textConstruction[key].constructor.name === 'Object') {
          if (textConstruction[key].willConvertCurrency) {
            str += `${textConstruction[key].label}: ${formatPrice(d[key])}\n`;
          }
        }
      }
      return str.slice(0, -1);
    },
  },
};
