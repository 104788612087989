import * as d3 from 'd3';
import { formatPrice } from '@/utils/common';
import { isEmptyObject } from '@/utils/validation';

export const d3HierarchyMixins = {
  methods: {
    /**
     * @description Commencer for D3 Hierarchy Bar Chart
     *
     * @param {String} domElementId - DOM element id which specified
     * when using the HierarchyChartD3
     *
     * @param {Object} data - Tree structure data type
     * @param {Number} receivedBarStep
     * @param {String} sumBy - Main value which will be summed to scale linearly with the chart's width
     *
     * @param {String[]} eachAfters - Additional values for when sumBy is not enough,
     * this functions the same as sumBy, but will not scale linearly with the chart's width.
     * Conversely, it will show alongside the main value.
     *
     * @param {String} endAlignmentFontSize - Specifying the font size, preferably in rem
     * @param {String} additionalBarTextDisplayType
     * @param {Boolean} enableChartAnimation
     * @param containerRef
     * @param {Boolean} enableSortDesc - Applies sort main values indicated by sumBy parameter.
     * @param {Boolean} isLoading - Handles chart loading
     */
    initializeD3HierarchyChart({
      domElementId,
      data,
      receivedBarStep,
      sumBy = '',
      eachAfters = [],
      endAlignmentFontSize = '1rem',
      additionalBarTextDisplayType = 'block',
      enableChartAnimation = false,
      containerRef,
      enableSortDesc = true,
    }) {
      if (!data || isEmptyObject(data)) {
        console.warn('No data is received!');
        return;
      }
      const tableData = { ...data };

      const width = containerRef.offsetWidth - 300;
      const margin = { top: 30, right: 30, bottom: 0, left: 320 };
      const duration = enableChartAnimation ? 500 : 0; //milliseconds
      const barStep = receivedBarStep || 40;
      const barPadding = 5 / barStep;
      const color = d3.scaleOrdinal([true, false], ['steelblue', '#aaa']);
      const yAxis = (g) =>
        g
          .attr('class', 'y-axis')
          .attr('transform', `translate(${margin.left + 0.5},0)`)
          .call((g) =>
            g
              .append('line')
              .attr('stroke', 'currentColor')
              .attr('y1', margin.top)
              .attr('y2', height() - margin.bottom),
          );
      const xAxis = (g) =>
        g
          .attr('class', 'x-axis')
          .attr('transform', `translate(0,${margin.top})`)
          .call(d3.axisTop(x).ticks(width / 80, 's'))
          .call((g) =>
            (g.selection ? g.selection() : g).select('.domain').remove(),
          )
          .attr('font-size', '0.8rem');
      const x = d3.scaleLinear().range([margin.left, width - margin.right]);

      const root = this.constructD3Object({
        d3,
        data: tableData,
        sumBy,
        eachAfters,
        enableSortDesc,
      });

      function height() {
        let max = 1;
        root.each(
          (d) => d.children && (max = Math.max(max, d.children.length)),
        );
        return max * barStep + margin.top + margin.bottom;
      }

      function down(svg, d) {
        if (!d.children || d3.active(svg.node())) return;

        // Rebind the current node to the background.
        svg.select('.background').datum(d);

        // Define two sequenced transitions.
        const transition1 = svg.transition().duration(duration);
        const transition2 = transition1.transition();

        // Mark any currently-displayed bars as exiting.
        const exit = svg.selectAll('.enter').attr('class', 'exit');

        // Entering nodes immediately obscure the clicked-on bar, so hide it.
        exit
          .selectAll('rect')
          .attr('fill-opacity', (p) => (p === d ? 0 : null));

        // Transition exiting bars to fade out.
        exit
          .transition(transition1)
          .attr('fill-opacity', 0)
          .remove();

        // Enter the new bars for the clicked-on data.
        // Per above, entering bars are immediately visible.
        const enter = bar(svg, down, d, '.y-axis').attr('fill-opacity', 0);

        // Have the text fade-in, even though the bars are visible.
        enter.transition(transition1).attr('fill-opacity', 1);

        // Transition entering bars to their new y-position.
        enter
          .selectAll('g')
          .attr('transform', stack(d.index))
          .transition(transition1)
          .attr('transform', stagger());

        // Update the x-scale domain.
        x.domain([0, d3.max(d.children, (d) => d.value)]);

        // Update the x-axis.
        svg
          .selectAll('.x-axis')
          .transition(transition2)
          .call(xAxis);

        enter
          .selectAll('.bar-value')
          .transition(transition2)
          .attr('x', (d) => x(d.value));
        // Transition entering bars to the new x-scale.
        enter
          .selectAll('g')
          .transition(transition2)
          .attr('transform', (d, i) => `translate(0,${barStep * i})`);

        // Color the bars as parents; they will fade to children if appropriate.
        enter
          .selectAll('rect')
          .attr('fill', color(true))
          .attr('fill-opacity', 1)
          .transition(transition2)
          .attr('fill', (d) => color(!!d.children))
          .attr('width', (d) => x(d.value) - x(0));
      }

      // Creates a set of bars for the given data node, at the specified index.
      const vm = this;

      function bar(svg, down, d, selector) {
        const g = svg
          .insert('g', selector)
          .attr('class', 'enter')
          .attr(
            'transform',
            `translate(0,${margin.top + barStep * barPadding})`,
          )
          .attr('text-anchor', 'end')
          .style('font', `${endAlignmentFontSize} sans-serif`);

        const bar = g
          .selectAll('g')
          .data(d.children)
          .join('g')
          .attr('cursor', (d) => (!d.children ? null : 'pointer'))
          .on('click', (event, d) => down(svg, d));

        bar
          .append('text')
          .attr('x', margin.left - 6)
          .attr('y', (barStep * (1 - barPadding)) / 2)
          .attr('dy', '.35em')
          .attr('font-size', '0.6rem')
          .text((d) => d.data.name);

        bar
          .append('rect')
          .attr('x', x(0))
          .attr('width', (d) => x(d.value) - x(0))
          .attr('height', barStep * (1 - barPadding))
          .attr('y', 0);

        bar
          .append('text')
          .attr('class', 'bar-value')
          .attr('x', (d) => x(d.value))
          .attr('y', (barStep * (1 - barPadding)) / 2)
          .attr('dy', '-0.40em')
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text((d) => `${formatPrice(d.value)} VNĐ`);

        const barXBarStepBarPaddingEachAfters = {
          bar,
          x,
          barStep,
          barPadding,
          eachAfters,
        };

        switch (additionalBarTextDisplayType) {
          case 'block':
            vm.constructAdditionalD3BarText(barXBarStepBarPaddingEachAfters);
            break;
          case 'inline':
            vm.constructInlineAdditionalD3BarText(
              barXBarStepBarPaddingEachAfters,
            );
            break;
          default:
            console.log('additionalBarTextDisplayType is not defined');
            break;
        }

        return g;
      }

      function up(svg, d) {
        if (!d.parent || !svg.selectAll('.exit').empty()) return;

        // Rebind the current node to the background.
        svg.select('.background').datum(d.parent);

        // Define two sequenced transitions.
        const transition1 = svg.transition().duration(duration);
        const transition2 = transition1.transition();

        // Mark any currently-displayed bars as exiting.
        const exit = svg.selectAll('.enter').attr('class', 'exit');

        // Update the x-scale domain.
        x.domain([0, d3.max(d.parent.children, (d) => d.value)]);

        // Update the x-axis.
        svg
          .selectAll('.x-axis')
          .transition(transition1)
          .call(xAxis);

        // Transition exiting text to the new x-scale before bars.
        exit
          .selectAll('.bar-value')
          .transition(transition1)
          .attr('x', (d) => x(d.value));

        // Transition exiting bars to the new x-scale.
        exit
          .selectAll('g')
          .transition(transition1)
          .attr('transform', stagger());

        // Transition exiting bars to the parent’s position.
        exit
          .selectAll('g')
          .transition(transition2)
          .attr('transform', stack(d.index));

        // Transition exiting rects to the new scale and fade to parent color.
        exit
          .selectAll('rect')
          .transition(transition1)
          .attr('width', (d) => x(d.value) - x(0))
          .attr('fill', color(true));

        // Transition exiting text to fade out.
        // Remove exiting nodes.
        exit
          .transition(transition2)
          .attr('fill-opacity', 0)
          .remove();

        // Enter the new bars for the clicked-on data's parent.
        const enter = bar(svg, down, d.parent, '.exit').attr('fill-opacity', 0);

        enter
          .selectAll('g')
          .attr('transform', (d, i) => `translate(0,${barStep * i})`);

        // Transition entering bars to fade in over the full duration.
        enter.transition(transition2).attr('fill-opacity', 1);

        // Transition entering text to the new x-scale after bars.
        enter
          .selectAll('.bar-value')
          .transition(transition2)
          .attr('x', (d) => x(d.value));

        // Color the bars as appropriate.
        // Exiting nodes will obscure the parent bar, so hide it.
        // Transition entering rects to the new x-scale.
        // When the entering parent rect is done, make it visible!
        enter
          .selectAll('rect')
          .attr('fill', (d) => color(!!d.children))
          .attr('fill-opacity', (p) => (p === d ? 0 : null))
          .transition(transition2)
          .attr('width', (d) => x(d.value) - x(0))
          .on('end', function() {
            d3.select(this).attr('fill-opacity', 1);
          });
      }

      function stack(i) {
        let value = 0;
        return (d) => {
          const t = `translate(${x(value) - x(0)},${barStep * i})`;
          value += d.value;
          return t;
        };
      }

      function stagger() {
        let value = 0;
        return (d, i) => {
          const t = `translate(${x(value) - x(0)},${barStep * i})`;
          value += d.value;
          return t;
        };
      }

      const parent = d3
        .select(domElementId)
        .html('')
        .append('div')
        .style('overflow-y', 'scroll')
        .style('overflow-x', 'hidden')
        .style('width', 'auto')
        .style('-webkit-overflow-scrolling', 'touch')
        .style('height', height() + 'px')
        .style('width', width);

      const svg = parent
        .append('div')
        .style('width', width)
        .append('svg')
        .attr('id', `${domElementId}-svg`)
        .attr('width', '100%')
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height()]);

      x.domain([0, root.value]);

      svg
        .append('rect')
        .attr('class', 'background')
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .attr('width', width + 600 + 'px')
        .attr('height', height)
        .attr('cursor', 'pointer')
        .on('click', (event, d) => up(svg, d));

      svg.append('g').call(xAxis);

      svg.append('g').call(yAxis);

      down(svg, root);
      return root;
    },
    /**
     * @name constructD3Object
     * @description Handles the configuration of d3 root hierarchy.
     *
     * @param d3 - main D3 Object
     * @param {Object} data - data that reflects the tree structure
     * @param {String} sumBy - specify which value to be summed
     * this value will be scaled linearly by the specified axis,
     * horizontally in this case.
     *
     * @param eachAfters - additional information
     * which will be displayed alongside the horizontal bars
     *
     * @param {Boolean} enableSortDesc
     * @returns {*}
     */
    constructD3Object({ d3, data, sumBy, eachAfters, enableSortDesc }) {
      const root = d3.hierarchy(data);

      if (sumBy) {
        root.sum((d) => d[sumBy]);
      }
      if (enableSortDesc) root.sort((a, b) => b.value - a.value);

      for (const eachAfter of eachAfters) {
        const dataKey = eachAfter.name.trim();
        root.eachAfter((d) => {
          let sum = d.data[dataKey] || 0;
          const children = d.children;
          let i = children && children.length;
          while (--i >= 0) sum += children[i][dataKey];
          d[dataKey] = sum;
        });
      }

      root.eachAfter(
        (d) =>
          (d.index = d.parent ? (d.parent.index = d.parent.index + 1 || 0) : 0),
      );
      return root;
    },
    /**
     *
     * @param bar
     * @param x
     * @param barStep
     * @param barPadding
     * @param  eachAfters
     * @returns void
     */
    constructAdditionalD3BarText({ bar, x, barStep, barPadding, eachAfters }) {
      for (const [index, eachAfter] of eachAfters.entries()) {
        const verticalSpacing = 0.6 * (index + 1);
        bar
          .append('text')
          .attr('class', 'bar-value')
          .attr('x', (d) => x(d[eachAfter]))
          .attr('y', (barStep * (1 - barPadding)) / 2)
          .attr('dy', `${verticalSpacing}em`)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text((d) => `${eachAfter}: ${d[eachAfter]}`);
      }
    },
    constructInlineAdditionalD3BarText({
      bar,
      x,
      barStep,
      barPadding,
      eachAfters,
    }) {
      bar
        .append('text')
        .attr('class', 'bar-value')
        .attr('x', (d) => x(d.value))
        .attr('y', (barStep * (1 - barPadding)) / 2)
        .attr('dy', `0.6em`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((d) => {
          let stringTemplate = '';
          for (const eachAfter of eachAfters) {
            stringTemplate += `${eachAfter.value}: ${d[eachAfter.name]}, `;
          }
          return stringTemplate.slice(0, -2);
        });
    },
  },
};
