<template>
  <div :class="getContainerClassState" :style="getContainerStyleState">
    <div :style="getHeightStyleState" class="animation">
      <div class="text-left">
        <h5
          class="mb-0 border-bottom border-dark d-inline-block"
          v-if="isNotFetching"
        >
          {{ title }}
        </h5>
      </div>
      <div :class="getLoadingClassState" style="z-index: 100">
        <div :class="getSpinnerClassState"></div>
        <div class="loading-message" v-if="isLoading">
          <p>
            Vui lòng chờ đợi trong giây lát
          </p>
        </div>
      </div>
      <div class="overlay-wrapper p-5 position-relative" :id="id"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'D3ObjectChartContainer',
  props: {
    isLoading: {},
    id: {},
    title: {
      type: String,
      defaultValue: '',
    },
    data: {
      type: Object,
      defaultValue: {},
    },
    enableBorder: {
      type: Boolean,
      defaultValue: true,
    },
  },
  computed: {
    isNotFetching() {
      return (
        !this.isLoading &&
        this.data &&
        Object.values(this.data).length > 0 &&
        this.title
      );
    },
    getContainerClassState() {
      return `${this.isLoading ? 'overlay' : ''} ${
        this.enableBorder ? 'border border-dark' : ''
      } overlay-block rounded`;
    },
    getContainerStyleState() {
      return { height: 'auto', overflow: this.isLoading ? 'hidden' : 'auto' };
    },
    getHeightStyleState() {
      return { height: this.isLoading ? '40vh' : '100vh' };
    },
    getLoadingClassState() {
      return `${this.isLoading ? 'overlay-layer' : ''} rounded bg-light`;
    },
    getSpinnerClassState() {
      return `${this.isLoading ? 'spinner' : ''} spinner-primary`;
    },
  },
};
</script>
<style scoped>
.animation {
  transition: all 0.5s;
}

.loading-message {
  position: absolute;
  top: 60%;
  left: 51%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
}
</style>
